@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'TT Hazelnuts Black';
    src: local('TT Hazelnuts Black'), local('TT-Hazelnuts-Black'),
        url('/fonts/TTHazelnuts-Black.woff2') format('woff2'),
        url('/fonts/TTHazelnuts-Black.woff') format('woff'),
        url('/fonts/TTHazelnuts-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Hazelnuts Medium';
    font-style: normal;
    font-weight: normal;
    src: local('TT Hazelnuts Medium'), local('TT-Hazelnuts-Medium'), url('/fonts/TTHazelnuts-Medium.woff') format('woff');
}



/* Set content font-families */
.ql-font-tt-hazelnuts-black {
    font-family: 'TT Hazelnuts Black';
}

.ql-font-tt-hazelnuts-medium {
    font-family: 'TT Hazelnuts Medium';
}

.ql-font-arial {
    font-family: 'Arial';
}



.ql-custom-size-1 {
    @apply text-sm;
}

.ql-custom-size-2 {
    @apply text-base;
}

.ql-custom-size-3 {
    @apply text-lg;
}

.ql-custom-size-3-5 {
    @apply text-xl;
}

.ql-custom-size-4 {
    @apply text-xl md:text-2xl;
}

.ql-custom-size-5 {
    @apply text-2xl md:text-5xl;
}

.ql-custom-size-6 {
    @apply text-3xl md:text-6xl;
}

.ql-custom-size-7 {
    @apply text-4xl md:text-7xl;
}

.ql-custom-size-8 {
    @apply text-5xl md:text-9xl md:leading-tight;
}


.ql-mt-0 {
    @apply mt-0;
}
.ql-mt-1 {
    @apply mt-1;
}
.ql-mt-2 {
    @apply mt-2;
}
.ql-mt-3 {
    @apply mt-3;
}
.ql-mt-4 {
    @apply mt-4;
}
.ql-mt-5 {
    @apply mt-5;
}
.ql-mt-6 {
    @apply mt-6;
}
.ql-mt-7 {
    @apply mt-7;
}
.ql-mt-8 {
    @apply mt-8;
}
.ql-mt-9 {
    @apply mt-9;
}
.ql-mt-10 {
    @apply mt-10;
}

.ql-mb-0 {
    @apply mb-0;
}
.ql-mb-1 {
    @apply mb-1;
}
.ql-mb-2 {
    @apply mb-2;
}
.ql-mb-3 {
    @apply mb-3;
}
.ql-mb-4 {
    @apply mb-4;
}
.ql-mb-5 {
    @apply mb-5;
}
.ql-mb-6 {
    @apply mb-6;
}
.ql-mb-7 {
    @apply mb-7;
}
.ql-mb-8 {
    @apply mb-8;
}
.ql-mb-9 {
    @apply mb-9;
}
.ql-mb-10 {
    @apply mb-10;
}


.ql-mt-0 {
    margin-top: 0 !important;
}
.ql-mt-1 {
    margin-top: 0.25rem !important;
}
.ql-mt-2 {
    margin-top: 0.5rem !important;
}
.ql-mt-3 {
    margin-top: 0.75rem !important;
}
.ql-mt-4 {
    margin-top: 1rem !important;
}
.ql-mt-5 {
    margin-top: 1.25rem !important;
}
.ql-mt-6 {
    margin-top: 1.5rem !important;
}
.ql-mt-7 {
    margin-top: 1.75rem !important;
}
.ql-mt-8 {
    margin-top: 2rem !important;
}
.ql-mt-9 {
    margin-top: 2.25rem !important;
}
.ql-mt-10 {
    margin-top: 2.5rem !important;
}

.ql-mb-0 {
    margin-bottom: 0 !important;
}
.ql-mb-1 {
    margin-bottom: 0.25rem !important;
}
.ql-mb-2 {
    margin-bottom: 0.5rem !important;
}
.ql-mb-3 {
    margin-bottom: 0.75rem !important;
}
.ql-mb-4 {
    margin-bottom: 1rem !important;
}
.ql-mb-5 {
    margin-bottom: 1.25rem !important;
}
.ql-mb-6 {
    margin-bottom: 1.5rem !important;
}
.ql-mb-7 {
    margin-bottom: 1.75rem !important;
}
.ql-mb-8 {
    margin-bottom: 2rem !important;
}
.ql-mb-9 {
    margin-bottom: 2.25rem !important;
}
.ql-mb-10 {
    margin-bottom: 2.5rem !important;
}


.ql-video.ql-align-center {
    margin: 0 auto;
}
.ql-video.ql-align-right {
    margin: 0 0 0 auto;
}

.ql-align-center {
    text-align: center;
}
.ql-align-justify {
    text-align: justify;
}
.ql-align-right {
    text-align: right;
}
