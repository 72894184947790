@import '@/quill.css';


.font-tt-hazelnuts-black {
    font-family: 'TT Hazelnuts Black', arial;
    font-weight: 900;
}
.font-tt-hazelnuts-medium {
    font-family: 'TT Hazelnuts medium', arial;
    font-weight: normal;
}

.text-red {
    color: rgb(243, 58, 58);
}


#header, #site-logo {
    transition: all 300ms;
}



#nav-content ul {
    font-size: 1.1rem;
}

#nav-content > ul > li > a {
    position: relative;
}

#nav-content > ul > li > a.active::before,
#nav-content > ul > li > a:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 120px;
    height: 36px;
    margin-left: -60px;
    background-image: url(/images/website/Stroke.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
}

@screen lg {
    #nav-content ul {
        font-size: 0.94rem;
    }
}



#language-dropdown {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'TT Hazelnuts Black', arial;
    font-weight: 900;
}


/* backdrop fallback */
.backdrop-blur.bg-white {
    background-color: rgba(255, 255, 255, 0.91);
}
.backdrop-blur.bg-dark {
    background-color: rgba(20, 20, 20, 0.8);
}

/* if backdrop is supported */
@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
    .backdrop-blur.bg-white {
        background-color: rgba(255, 255, 255, 0.8);
    }
    .backdrop-blur.bg-dark {
        background-color: rgba(20, 20, 20, 0.5);
    }
    .backdrop-blur {
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
    }
}




.contact-form input,
.contact-form textarea {
    color: currentColor;
    font-family: sans-serif;
    font-size: 1rem;
    height: 50px;
    padding: 12px 20px 12px 20px;
    padding-left: 0;
    width: 100%;
    border-radius: 0px;
    margin-bottom: 0px;
    border: none;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid currentColor;
}


.contact-form textarea {
    min-height: 160px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-bottom: 1px solid currentColor;
    box-shadow: 0 1px 0 0 currentColor;
}

.contact-form ::placeholder {
    color: currentColor;
    opacity: 1;
}

.contact-form .circle-button {
    height: 100px;
    width: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    color: currentColor;
    border: 3px solid currentColor;
}

.contact-form .circle-button:hover {
    border-color: rgb(243, 58, 58) !important;
}
.contact-form .circle-button:active {
    transform: translateY(1px);
}

section img {
    display: inline-block;
    max-width: 100%;
}

section iframe {
    max-width: 100%;
}